import React, { FC, useState } from "react";
import { ModalHomeDiv, ModalHomeContentDiv, ModalHomeTextDiv } from "./styles";

const ModalHome: FC = () => {
  const [isClassApplied, setIsClassApplied] = useState(false);

  // Função para aplicar a classe
  const handleClick = () => {
    console.log("Close button clicked");
    setIsClassApplied(true);
  };

  return (
    <ModalHomeDiv className={isClassApplied ? 'ocult' : ''}>
      <ModalHomeContentDiv>
        <button onClick={handleClick}>
          <span className="icon icon-times"></span>
        </button>
        
        <ModalHomeTextDiv>
          <span>Comunicado</span>

          <strong>
            3R Petroleum e Enauta agora são Brava Energia.
          </strong>

          <p>
            Nascemos como a principal companhia independente de óleo e gás do Brasil. A partir da junção de duas forças, somos da <strong>terra e do mar.</strong>
          </p>

          <p>
            Com a bravura que é nossa marca.
          </p>

          <p className="highlight">
            Para maximizar resultados e gerar valor.
          </p>
        </ModalHomeTextDiv>
      </ModalHomeContentDiv>
    </ModalHomeDiv>
  );
};

export default ModalHome;
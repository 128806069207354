import styled from "styled-components";
import ModalBg from "../../assets/images/modal-home-background.jpg"

const ModalHomeDiv = styled.div`
  left: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  &:before {
    background-color: ${({ theme }) => theme.black};
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.9;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.ocult {
    display: none;
  }
`;

const ModalHomeContentDiv = styled.div`
  width: 37.5rem;
  height: 30.19rem;
  padding: 30px 80px 60px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  background: url(${ModalBg}) no-repeat center;
  background-size: contain;

  button {
    background-color: #1b0572;
    border: 2px solid #1b0572;
    border-radius: 5px;
    width: 32px;
    height: 32px;
    padding: 0;
    margin-left: auto;
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;

    .icon-times {
      &:before {
        font-weight: bolder;
        font-size: 15px;
        color: ${({ theme }) => theme.white_900};
      }
    }
  }
  
  @media only screen and (max-width : 1023px) {
    max-height: 505px;
  }

  @media only screen and (max-width : 767px) {
    padding: 30px 60px 60px 40px;

    button {
      top: 10%;
    }
  }

  @media only screen and (max-width : 519px) {
    max-height: 400px;
    background-size: cover;
    padding: 30px 60px 20px 40px;
  }

  @media only screen and (max-width : 419px) {
    max-height: 300px;
    padding: 30px 50px 20px 20px;
  }

`;

const ModalHomeTextDiv = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 25px;

  span {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }

  p {
    margin: 0;

    &.highlight {
      font-weight: 700;
      font-size: 18px;
      color: #d2fb00;
    }
  }

  @media only screen and (max-width : 519px) {
    padding-bottom: 20px;
    font-size: 14px;
    gap: 15px;

    span {
      font-size: 15px;
    }

    p {
      &.highlight {
        font-size: 15px;
      }
    }
  }

  @media only screen and (max-width : 419px) {
    padding-bottom: 0;
    font-size: 12px;
    gap: 10px;

    span {
      margin-bottom: 0;
      font-size: 13px;
    }

    p {
      &.highlight {
        font-size: 13px;
      }
    }
  }
`;

export { ModalHomeDiv, ModalHomeContentDiv, ModalHomeTextDiv };
